import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from './Copyright';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMobileAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100vw',
  },
  icon: {
    color: theme.palette.secondary.light,
  },
  font: {
    fontFamily: 'Roboto Condensed',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: '#efe7e4',
  },
  text: {
    color: theme.palette.background.default,
  },
}));

export default function Contact() {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={4} align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.text} variant="h6">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className={classes.icon}
              ></FontAwesomeIcon>
              <Box p={1} component="span">
                Chicago, IL
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.text} variant="h6">
              <FontAwesomeIcon
                icon={faMobileAlt}
                className={classes.icon}
              ></FontAwesomeIcon>
              <Box p={1} component="span">
                815.600.0561
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.text} variant="h6">
              <FontAwesomeIcon
                icon={faEnvelope}
                className={classes.icon}
              ></FontAwesomeIcon>
              <Box p={1} component="span">
                <Link
                  href="mailto:jennmez@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                >
                  jennmez@gmail.com
                </Link>
              </Box>
            </Typography>
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Container className={classes.root}>
      <Box p={3} borderBottom={1}>
        <Typography className={classes.font} variant="h3">
          Get In Touch
        </Typography>
      </Box>
      <Box p={3}>
        <Grid container spacing={1}>
          <FormRow />
        </Grid>
      </Box>
      <Copyright />
    </Container>
  );
}
