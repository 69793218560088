import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#efe7e4',
    opacity: '75%',
  },
}));

export default function Copyright() {
  const classes = useStyles();
  return (
    <Box pb={1}>
      <Typography variant="body2" className={classes.text} align="center">
        {'Copyright © '}
        Jenn Misewicz
        {` ${new Date().getFullYear()}`}
      </Typography>
    </Box>
  );
}
